.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 40px 20px;

  h1 {
    color: #2c3e50;
    margin-bottom: 20px;
    font-size: 2rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 960px;
  }

  li {
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
  }

  a {
    display: flex; // Modifié pour flex afin d'aligner icône et texte
    align-items: center; // Centre verticalement l'icône avec le texte
    padding: 20px;
    text-align: center;
    color: #3498db;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s;

    &:hover {
      color: #2980b9;
    }

    .MuiSvgIcon-root { // Styles spécifiques pour les icônes
      margin-right: 10px; // Ajoute de l'espace entre l'icône et le texte
    }
  }
}
