.notice-container {
    background-color: #fff;
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.05);
    border-radius: 8px;
    line-height: 1.6;

    .path {
        font-size: 0.9rem;
        color: #555;
        margin-bottom: 20px;

        a {
            color: #1a73e8; // Brighter blue for better visibility
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    h1 {
        border-bottom: 2px solid #eee;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    h2 {
        color: #007bff;
        margin-top: 20px;
    }

    p, li {
        color: #666;
    }

    ul {
        padding-left: 20px;
        list-style-type: disc; // makes it visually clearer
    }

    ol {
        counter-reset: step-counter;
        list-style-type: none;
        padding-left: 0;

        li {
            counter-increment: step-counter;
            margin-bottom: 10px;

            &::before {
                content: counter(step-counter) ".";
                margin-right: 10px;
                font-weight: bold;
                color: #007bff;
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin-top: 20px;
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;

        button {
            padding: 10px 20px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}
