.topBar {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative; // Ajouté pour positionnement absolu des résultats de recherche

    .logoContainer {
        margin-right: 20px; // Espace entre le logo et la barre de recherche

        a {
            display: inline-block;
            height: 50px;
            width: 50px;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain; // Assure que le logo est bien ajusté
            }
        }
    }

    .searchContainer {
        flex-grow: 1; // Permet à la barre de recherche de prendre l'espace disponible
        position: relative; // Nécessaire pour le positionnement des résultats de recherche

        .searchInput {
            width: 95%; 
            padding: 8px 10px;
            border: 1px solid #ccc;
            border-radius: 20px; // Bordures arrondies pour un look moderne
            box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);

            &:focus {
                outline: none;
                border-color: #007bff; // Changement de couleur lors de la focalisation
            }
        }

        .searchResults {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: #fff;
            box-shadow: 0 4px 8px rgba(0,0,0,0.2);
            z-index: 10; // Assure que les résultats sont au-dessus des autres éléments
            border-radius: 0 0 8px 8px; // Arrondi en bas du menu déroulant
            overflow: hidden; // Masque les débordements

            li {
                border-bottom: 1px solid #eee;

                &:last-child {
                    border-bottom: none; // Pas de bordure pour le dernier élément
                }

                a {
                    display: block;
                    padding: 10px;
                    color: #333;
                    text-decoration: none;
                    transition: background-color 0.2s;

                    &:hover {
                        background-color: #f8f9fa; // Effet de survol subtil
                    }
                }
            }
        }
    }
}
